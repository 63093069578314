<template>
  <div class="CalculatedBlock">
    {{ result }}
  </div>
</template>

<script>
import get from 'lodash/get'

import { unpack } from '@/helpers/computed'

export default {
  name: 'CalculatedBlock',
  props: {
    componentId: String,
    component: Object,
    componentData: {},
    userData: Object,
  },
  computed: {
    ...unpack('component', ['calculation', 'inputs']),
    result() {
      const formula = getCalculationFormula(this.calculation)
      const inputValues = this.inputs.map(key => get(this.userData, key))

      return formula(...inputValues)
    },
  },
}

function getCalculationFormula(calculation) {
  switch (calculation) {
    case 'homestead_savings':
      return homesteadSavings
  }
}

// function homesteadSavings(zipcode, buildingType, paymentType)
function homesteadSavings() {
  return 3
}
</script>
